body {
    font-family: Arial, sans-serif;
    background-color: #f8f9fa;
    margin: 0;
    padding: 0;
}

.refund-policy {
    max-width: 70%;
    margin: 40px auto;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow-y:auto;
    background-color: #fff;
   
}

.refund-policy h1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333333;
   
}

.refund-content h2 {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #0056b3;
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    padding-left: 30px;
    width: 100%;
}
.refund-content{
    text-align: justify !important;
  text-justify: inter-word;
    padding-left: 100px;
    padding-right: 100px;
}

.refund-content p {
    font-size: 16px;
    line-height: 1.5;
    color: #555555;
    margin-left:6%;
}

.refund-content ul, .refund-content ol {
    margin: 10px 0;
    padding-left: 20px;
    color: #555555;
    margin-left:0%;
}

.refund-content ul li, .refund-content ol li {
    margin-bottom: 8px;
    margin-left:7%;
}

.refund-content a {
    color: #0056b3;
    text-decoration: none;
}

.refund-content a:hover {
    text-decoration: underline;
}
