.about-container{
    display: flex;
    justify-content: center;
}
.about-message{
    background-color:#80f2f0;
    width:70%;
    border-radius:5px;
    padding:15px;
    height:27vh;
    margin-top:16vh;
    margin-left:20vw;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
}
.about-media{
    font-size:21px;
    font-style:normal;
    color:#a124f0;
}
.about-vision-container{
    display: flex;
    justify-content: center;
}
.about-vision-message{
    background-color:#80f2f0;
    width:70%;
    border-radius:5px;
    padding:15px;
    height:27vh;
    margin-top:16vh;
    margin-left:20vw;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    display:flex;
    align-items:center;
    justify-content:center;
}
.about-mission-container{
    display: flex;
    justify-content: center;
}
.about-mission-message{
    background-color:#80f2f0;
    width:80%;
    border-radius:5px;
    padding:25px;
    height:27vh;
    margin-top:16vh;
    margin-left:20vw;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    display:flex;
    align-items:center;
    justify-content:center;
}