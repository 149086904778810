.voice-acccount-container{
    width: 100%;
    display: flex;
}
.voice-acccount-left{
    width:37%;
    padding-top:20px;
    padding-left:20px;
}
.voice-credits-card{
    /* height:20%; */
    width:70%;
    padding:20px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
}
.voice-acccount-right{
    width:53%;
    padding-top:30px;
    padding-left:20px;
 
}
.slim-35{
    width:35%;
}
.slim-30{
    width:30%;
}
.slim-70{
    width:70%;
}
.inpu-labe{
    width:100%;
    height:100%;
    display: inline-block;
}
.voice-icoo{
    height:48px;
    width:30px;
    margin:0 px;
    color:#a124f0;
}
.credits-card-headers{
    margin:4px;
}
.payment-btn{
    background-color:#4287f5;
    border-radius:5px;
    color:#fff;
    padding:11px 20px;
    border:none;
    outline:none;
    cursor:pointer;
}
.talk-to-us{
    padding:9px 30px;
    background-color:#25d366;
    color:#fff;
    border:none;
    outline:none;
    display:flex;
    align-items:center;
    gap:10px;
    border-radius:5px;
    cursor:pointer;
    margin-top:14vh;
    margin-left:6%;
}
.talk-to-us >p{
    font-size:18px;
    font-weight:540;
    margin:0px;
}
.talk-wrapper{
    text-decoration:none;
    color:black;
}
.whatsapp-icon{
    height:42px;
    width:38px;
    object-fit:content;
}
.pay-div{
    margin-top:10vh;
    margin-left:6%;
}