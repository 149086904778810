.create-bot-container{
    padding:10px;
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    margin-left:26px;
    margin-right:35px;
    margin-top:10px;
    border-radius:7px;
    border:2px solid black;
    padding-top:17px;
    padding-left:25px;
}