.business-request-card{
    height:14vh;
    width:84%;
    background-color:#fff;
    display:flex;
    flex-direction:row;
    justify-content:space-evenly;
    align-items:center;
    margin-top:15px;
    margin-bottom:15px;
    margin-left:10px;
    border-radius:6px;
    padding:8px;
}
.requests-container{
    width: 100%;
    height:90vh;
    overflow-y:auto;
    background-color:#d4d8d9;
}