.businesses-container{
    width: 100%;
    height:90vh;
    overflow-y:auto;
    background-color:#d4d8d9;
}
.business-card{
    height:14vh;
    width:94%;
    background-color:#fff;
    display:flex;
    flex-direction:row;
    align-items:center;
    /* justify-content:space-evenly; */
    margin-top:15px;
    margin-bottom:15px;
    margin-left:10px;
    border-radius:6px;
    padding:8px;
    /* color:white; */
}
.business-daat{
    display:flex;
    flex-direction:column;
    align-items:center;

}
.business-daat h4{
    margin:4px;
}
.authenticate-btn{
    padding:10px;
    color:#fff;
    padding:8px 26px;
    border-radius:7px;
    border:none;
    outline:none;
    cursor:pointer;
    font-weight:39px;
    font-size:20px;
    background-color: #a124f0;
}
.with-icon{
    display:flex;
    flex-direction:row;
    align-items:center;
    gap:5px;
    width:19%;
}
.business-icon{
    height:80px;
    width:97px;
    object-fit: contain;
}
.business-icoo{
    color:#a124f0;
}
.addBusiness-icon{
    cursor:pointer;
    position:fixed;
    top:86%;
    right:6%;
    height:58px;
    width:55px;
    color:#4287f5;
}
.image-container{
    width:9%;
}
.business-daat2{
    display:flex;
    flex-direction:column;
    /* align-items:center; */
    width:30%;
}
.daat2-data{
    margin-right:33%;
}
.business-daat2 h4,.business-daat1 h4{
    margin:4px;
}
.business-daat2 h2{

}
.business-daat1{
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    padding-left:29px;
    width:20%;
}
.authbtn-container{
    width:;
    padding-left:4%;
}