.voicecampaign-container{
    width: 100%;
}
.voicecampaign-action-bar{
    background-color:#d591ff;
    height:120px;
    width:100%;
    display:flex;
    justify-content:space-between;

}
.action-title{
    margin-left:16px;
}
.voicecampaignAddBtn{
    height:40px;
    width:110px;
    border-radius:10px;
    background-color:;
    border:none;
    display:flex;
    justify-content:center;
    align-items:center;
    gap:7px;
    cursor:pointer;
    margin-top:51px;
    margin-right:9px;
}
.sendVoiceBtn{
    height:40px;
    width:120px;
    border-radius:10px;
    background-color:;
    border:none;
    display:flex;
    justify-content:center;
    align-items:center;
    gap:7px;
    cursor:pointer;
    margin-top:7px;
}
.action-right{
    margin-right:20px;
    
}
.voicecampaignAddBtn:hover{

}
.plus-icon{
    height:13px;
    width:13px;
}
.campaignModalContainer{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100vh;
    background-color:rgba(0,0,1,0.4);
    display:flex;
    justify-content:center;
}
.campaignForm{
    background-color:#c46bfa;
    width:30%;
    box-sizing: border-box;
    height:40vh;
    margin-top:10%;
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    padding-left:3%;
    /* color:white; */
    box-shadow:4px 4px 5px rgba(0, 0, 0, 0.1);
}
.modal-header{
    width:90%;
    display:flex;
    align-items:center;
    justify-content:space-between;
    gap:10%;
}
.campaignInput,.categoryInput{
    width:80%;
    padding:8px 6px;
    margin-bottom:9px;
    border-radius:5px;
    outline:none;
    box-sizing:border-box;
    border:none;
}
.labels{
    font-size:18px;
    font-weight:500;
    margin-bottom:5px;
}
.modal-submit{
    border:none;
    background-color:;
    border-radius:5px;
    margin-left:33%;
    padding:5px;
    cursor:pointer;
    
}
.action-text{
    margin-top:5px;
    margin-left:16px;
    padding-left:0px;
}
.action-gap{
    display: flex;
    flex-direction:column;
    justify-content: space-around;
    align-items: flex-start;
}