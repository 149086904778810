.business-profile{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left:60px;
    height:79vh;
    overflow-y: auto;

}
.business-profile-container{
    display: flex;
    flex-direction: row;
    width:100%;
    justify-content: space-evenly;
    padding-top:5px;
}
.business-qr-logo{
    height:59px;
    width:70px;
}
.business-qr-right-header{
    display: flex;
    align-items: center;
    gap:5px;
}
.business-profile-left{
    width:40%;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    height:55vh;
    padding:20px;
    flex-direction:column;
    align-items:center;
    margin-top:20px;
}
.business-profile-left-details{
    display:flex;
    flex-direction:row;
    gap:4px;
    align-items:center;
    margin:0px;
}
.business-profile-logo{
    height:69px;
    width:100px;
}
.business-profile-left-details h4,.business-profile-left-details p{
    margin:2px !important;
    padding:2px !important;
}
.business-profile-right{
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    height:55vh;
    width:35%;
    padding:23px;
    display:flex;
    flex-direction:column;
    align-items:center;
    margin-top:20px;
}
.download-qr{
    height:30px;
    width:30px;
    cursor:pointer;
}
.downlad-container{
    display:flex;
    justify-content:flex-end;
    width:100%;
}
.qr-poster{
    display:flex;
    justify-content:center;
    align-items:center;
    position:absolute;
    top:0px;
    left:0px;
    width:100vw;
    z-index:5;
    height:100vh;
    background-color:rgba(0,1,1,0.4);
}
.poster-outer{
    height:100%;
    width:40%;
}
.poster-back{
    background-size:cover;
    height:100%;
}
.qr-size-poster{
    height:188px;
    width:195px;
}
.download-poster{
    border-radius:8px;
    background-color:#4287f5;
    border:none;
    color:#fff;
    height:36px;
    width:140px;
    margin-left:10px;
    cursor:pointer;
}
.close-poster{
    border-radius:8px;
    background-color:red;
    border:none;
    color:#fff;
    height:36px;
    width:140px;
    margin-left:10px;
    cursor:pointer;
}
.poster-btns{
    display:flex;
    flex-direction:column;
    gap:7px;
}
.poster-name{
}