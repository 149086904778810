.signin-container{
    background-color:#a124f0;
    height:100vh;
    width:100vw;
    position:absolute;
    top:0;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
}
.sign-inner{
    background-color:#e4c9f5;
    width:34%;
    padding:10px;
    border-radius:5px;
    display: flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;

}
.wizImage{
    height:64px;
    width:69px;
    margin-bottom:10px;

}
.googleImage{
    height:54px;
    width:59px;
}
.socialmedia-btn{
    display: flex;
    gap:8px;
    border-radius:5px;
    border:none;
    padding:10px;
    padding-left:15px;
    padding-right:15px;
    cursor:pointer;
}