.campaignForm2{
    background-color:#c46bfa;
    width:30%;
    box-sizing: border-box;
    height:50vh;
    margin-top:10%;
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    padding-left:3%;
    /* color:white; */
    box-shadow:4px 4px 5px rgba(0, 0, 0, 0.1);
    overflow-y:auto;
}
.campaignForm3{
    background-color:#c46bfa;
    width:30%;
    box-sizing: border-box;
    height:67vh;
    margin-top:10%;
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    padding-left:3%;
    /* color:white; */
    box-shadow:4px 4px 5px rgba(0, 0, 0, 0.1);
    overflow-y:auto;
    padding-bottom:5px;
}
.voice-container{
    display:flex;
    padding:10px;
}
.avail-audios{
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    padding-top:10px;
    margin-top:5px;
}
.choose-file{
    display:flex;
      flex-direction:column;
    align-items:flex-start;
    padding:10px;
}
.voicecampaignAddBtn2{
    height:40px;
    width:120px;
    border-radius:10px;
    background-color:;
    border:none;
    display:flex;
    justify-content:center;
    align-items:center;
    gap:7px;
    cursor:pointer;
    margin-top:27px;
    margin-right:9px;
}
.addingGroupBtn{
    height:30px;
    width:100px;
    border-radius:10px;
    background-color:;
    border:none;
    display:flex;
    justify-content:center;
    align-items:center;
    gap:7px;
    cursor:pointer;
    margin-top:10px;
}