.user-confirmation-container{
    height: 100vh;
}
.thanks-box{
    background-color:#8B0303;
    height:23vh;
    width:75vw;
    border-radius:18px;
    padding:5px;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    color:#fff;
}
.confirmation-box{
    height:80%;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
}
.registerUserContainer2{
    display:flex;
    flex-direction:column;
    height:100vh;
    width:100vw;
    position:absolute;
    top:0;
    left:0;
    z-index:3;
}