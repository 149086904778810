.deleteModalContainer{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100vh;
    background-color:rgba(0,0,1,0.4);
    display:flex;
    justify-content:center;
}
.deleteForm{
    background-color:#c46bfa;
    width:30%;
    box-sizing: border-box;
    height:40vh;
    margin-top:10%;
    display:flex;
    flex-direction:column;
    align-items:center;
    padding-left:3%;
    /* color:white; */
    box-shadow:4px 4px 5px rgba(0, 0, 0, 0.1);
    padding:20px;
}
.handlingBtns{
    width:80%;
    display: flex;
    flex-direction:row;
    justify-content: sapce-evenly;
    gap:20%;
}
.confirmDelete{
    margin-left:10%;
    height:40px;
    width:120px;
    border-radius:10px;
    background-color:;
    border:none;
    display:flex;
    justify-content:center;
    align-items:center;
    gap:7px;
    cursor:pointer;
}
.delete-cross{
    margin-left:84%;
}
.cancelDelete{
    height:40px;
    width:120px;
    border-radius:10px;
    background-color:;
    border:none;
    display:flex;
    justify-content:center;
    align-items:center;
    gap:7px;
    cursor:pointer;
}