.chatbot-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* background-color: #f0f0f0; */
    margin-left:auto;
    margin-right:26px;
}

.chatbot-container {
    width: 370px;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    background-color: #fff;
    display: flex;
    height: 80vh;
    flex-direction: column;
}

.chatbot-container.collapsed {
    height: 50px;
    transition: height 0.3s;
}

.chatbot-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px;
    background-color: #007bff;
    color: #fff;
}
.chatbot-header2 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
    background-color: #007bff;
    color: #fff;
}

.profile-icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}
.hide-me{
    display: none !important;
}
.header-icons {
    display: flex;
    gap: 10px;
}

.chatbot-body {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
    background-color: #f9f9f9;
    max-width: 100%;
    display:flex;
    flex-direction:column;
    /* justify-content: flex-end; */
    height:100%;
}

.chatbox {
    padding: 8px 12px;
    margin-bottom: 10px;
    border-radius: 18px;
    max-width: 75%;
    word-wrap: break-word;
}

.questionBox {
    align-self: flex-end;
    background-color: #007bff;
    color: #fff;
    text-align: left;
    margin-left: auto;
}

.answerBox {
    align-self: flex-start;
    background-color: #e9ecef;
    color: #000;
    text-align: left;
    margin-right: auto;
}

.chatbot-input {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #fff;
    border-top: 1px solid #ccc;
}

.chat-input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 20px;
    margin-right: 10px;
}
.profile-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%; /* Makes the image round */
    transition: transform 0.3s ease-in-out; /* Add a smooth transition effect */
}

.profile-icon:hover {
    transform: scale(1.1); /* Scales the image up on hover */
}

.chatbot-container.collapsed {
    height: 50px;
    transition: height 0.3s;
}



.chatbot-container {
    width: 370px;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    position: relative;
    transition: width 0.3s ease, height 0.3s ease; /* Add transitions for width and height */
}

.chatbot-container.collapsed {
    width: 50px; /* Collapsed width */
    height: 50px; /* Collapsed height */
    margin-top:65vh;
}


.typing {
    font-style: italic;
    color: #aaa;
}

/* Typing animation */
@keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
}

.typing::after {
    content: '...';
    display: inline-block;
    animation: blink 1s infinite;
}
.chatbox-typing{
    display:flex;
    flex-direction: row;
    align-items: center;

}
.chatbox-typing p{
    font-style:italic;
    color:#aaa;
    margin-right: 6px;
}