.row-update{
    /* display: flex; */
    /* height: 100%;
    justify-content: space-evenly;
    align-items: center;
    gap:5px; */

}
.update-row{
    color:blue;
    height:20px;
    width:20px;
    margin-right:7px;
    cursor:pointer;
}
.update-row:hover{
  
}
.delete-row{
    color:red;
    height:20px;
    width:20px;
    cursor:pointer;
}
.delete-row:hover{
   
}
.rhap_volume-controls,.rhap_additional-controls{
    display:none !important;
    height:0px;
}
.rhap_main-controls{
    margin-left:28%;
}
.rhap_main{
    /* display:flex;
    flex-direction:column;
    align-items:center; */
}