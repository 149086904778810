.business-container{
    width:100%;
}
.parameter-cell {
    word-spacing: 2px;
  }
.slimer{
    width:30%;
}
.register-table-style{
    height:70vh;
    width:100%;
    overflow-y:auto;
    margin-right:20vw;
    display:flex;
    flex-direction:row;
    justify-content:center;
}
.my-table-style{
    width:58%;
}
.businessInput{
    border:none;
    outline:none;
    border-radius:7px;
    padding-left:8px;
    background-color:#d591ff;
    height:25px;
    width:70%;
}
.create-business-btn{
    position:fixed;
    bottom:43px;
    right:34px;
    height:43px;
    width:127px;
    color:white;
    padding:6px 30px;
    border-radius:7px;
    border:none;
    outline:none;
    cursor:pointer;
    font-weight:39px;
    font-size:20px;
    background-color:#4287f5;
}
.Business-action-bar{
    background-color:#d591ff;
    height:90px;
    width:100%;
    display:flex;
    justify-content:space-between;
}
.business-register-form-container{
    /* color:white; */
    width:100%;
    display:flex;
}
.left-icon-action{
    display:flex;
    align-items:center;
    gap:5px;
}
.wrapper{
    width:450px;
    padding:30px;
}
.wrapper .input-data{
    height:30px;
    width:100%;
    position:relative;
}
.wrapper .input-data input{
    width:100%;
    height:100%;
    border:none;
    outline:none;
    font-size:17px;
    border-bottom: 2px solid silver;
}
@keyframes label-up {
    from { transform: translateY(0); }
    to { transform: translateY(-20px); font-size: 14px; color: #a124f0; }
  } 

.wrapper .input-data input:focus ~ label,.wrapper .input-data input:valid ~ label{
    transform:translateY(-20px);
    font-size:15px;
    color:#a124f0;
    pointer-events: none;
  transition: all 0.3s ease-in-out;
    animation: label-up 0.3s ease-in-out forwards; 
}
.wrapper .input-data label{
    position:absolute;
    bottom:10px;
    left:0;
    color:grey;
    pointer-events: none;
    transition:all 0.3s ease-in-out; 
}
.avatar-preview{
    border-radius:50%;
    height:170px;
    width:170px;
    object-fit:contain;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}
.choose-image{
    height:170px;
    width:170px;
    cursor:"pointer";
}
.avatar-labe{
    cursor:pointer;
}
.logo-container input[type="file"] {
    display: none; /* Hide the file input visually */
}
.logo-container{
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left:20px;
    padding-top:7px;
    padding-bottom:0px;
}
.avatar-label{
    padding-left:4px;
    font-size:21px;
    font-weight:bold;
    /* color:grey; */
}
.avatar-labe{
    margin-left:15%;
}
 /* .input-data .underline{
    position: absolute;
    height: 2px;
    width: 100%;
    bottom: 0;
    left:0;
  }
  .input-data .underline:before{
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background: #a124f0;
    transform: scaleX(0);
    width: calc(100% - 4px);
    transform-origin: center;
    transition: transform 0.3s ease;
  }
  .input-data input:focus ~ .underline:before,
  .input-data input:valid ~ .underline:before{
    transform: scaleX(1);
  }  */