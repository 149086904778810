.recordingusers-container{
    width:100%;
    padding:5px;
}
.table-style{
    width:90%;
}
.slim{
    width:10%;
}
.slim-2{
    width:30%;
}
thead tr{
    background-color:#f08843;
    height:40px;
}
tbody tr{
    height:34px;
}
tbody tr:nth-child(even){
    background-color:#f1edf5;
}
tbody tr:nth-child(odd){
    background-color:#f7f5fa;
}