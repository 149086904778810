.chatbot-wrapper2{
    display: flex;
    justify-content: center;
    height: 100vh;
    /* background-color: #f0f0f0; */
    margin-left:26px;
    margin-top:10vh;
}

.chatbot-container2 {
    width: 770px;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    background-color: #fff;
    display: flex;
    height: 60vh;
    flex-direction: column;
}