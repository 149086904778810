.disclaimer-container {
    max-width: 70%;
    margin: 40px auto;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.disclaimer-container h1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333333;
    text-align: center;
}

.disclaimer-content {
    word-wrap: break-word;
    word-break: break-word;
    text-align: justify !important;
    text-justify: inter-word;
    padding-left: 100px;
    padding-right: 100px;
}

.disclaimer-content h2 {
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #0056b3;
    display:flex;
    flex-direction:column;
    align-items:flex-start;
    
    width: 100%;
}

.disclaimer-content p {
    font-size: 16px;
    line-height: 1.5;
    color: #555555;
}

.disclaimer-content ul {
    margin: 10px 0;
    padding-left: 20px;
    color: #555555;
}

.disclaimer-content ul li {
    margin-bottom: 8px;
}

.disclaimer-content a {
    color: #0056b3;
    text-decoration: none;
}

.disclaimer-content a:hover {
    text-decoration: underline;
}