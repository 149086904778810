.sidebar-option{
    height:45px;
    width:154px;
    background-color: #fadb41;
    border:none;
    outline: none;
    border-radius:8px;
    font-weight:bold;
    cursor:pointer;
}
.sidebar-container{
    display:flex;
    flex-direction:column;
    height:100%;
    justify-content:flex-start;
    gap:5%;
    padding:0% 2%;
    background-color:#f4f2f5;
    width:10%;
    overflow-y:auto;
}
::-webkit-scrollbar {
    width: 2px;
  }
.sidebar-options{
    display:flex;
    flex-direction:row; 
    align-items:center;
    justify-content:space-between;
    gap:6px;
    width:160px;
    cursor:pointer;
}
.arrowDrop{
    height:27px;
    width:29px;
    margin-top:2px;
}
.arrowUp{
    height:27px;
    width:29px;
    margin-top:2px;
}
.section-data p:hover{
    background-color:#ebebeb;
    cursor:pointer;
}
.sider{
    display:flex;
    align-items:center;
    gap:5px;
}
.showHigh{
    background-color:#4287f5;
    color:white;
    border-radius:5px;
    padding-top:2px;
    padding-bottom:2px;
}
.showHigh:hover{
    background-color:#4287f5;
    color:white;
}