.new-sidebar-options{
    display:flex;
    flex-direction:row; 
    align-items:center;
    gap:6px;
    width:160px;
    cursor:pointer;
}
.new-sidebar-container{
    display:flex;
    flex-direction:column;
    height:100%;
    justify-content:flex-start;
    padding:0% 2%;
    background-color:#f4f2f5;
    width:10%;
    padding-top:8%;
    overflow-y:auto;
}