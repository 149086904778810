.btn{
    color:white;
    padding:6px 30px;
    border-radius:7px;
    border:none;
    outline:none;
    cursor:pointer;
    font-weight:39px;
    font-size:20px;
    height:36px;
    width:114px;
    margin-right:18px;
}
.filter-btn{
    background-color:#fff;
    color:#a124f0;

}
.send-btn{
    background-color:#fff;
    color:#a124f0;
    border-radius:9px;
    outline: none;
    border:none;
    height:40px;
    width:7vw;
    transition: background-color 0.2s ease-in-out;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}
.send-btn:hover{
    cursor:pointer;
   box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}
.navbar-container{
    background-color:#a124f0;
    display:flex;
    flex-direction:row;
    align-items:center;
}
.navbar-right-content{
    margin-left:auto;
    margin-right:20px;
    display:flex;
    align-items:center;
    gap:15px;
    color:white;
}
.navbar-user-icon{
    height:26px;
    width:26px;
}
.navbar-title{
    margin-left:15px;
    color:white;
    display:flex;
    flex-direction:row;
    align-items:center;
}
.wiz-logo{
    height:45px;
    width:53px;
    
}