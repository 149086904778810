.horizontal-align{
    display: flex;
    align-items: center;
    height:90px;
}
/* Container for the whole component */
.container {
    background-color: #f8f8f8;
    padding: 20px;
    min-height: 100vh;
}

/* Individual chat card */
.chat-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 15px;
    width:32vw;
}

/* Chat info section containing the icon and name */
.chat-info {
    display: flex;
    align-items: center;
}

/* Business icon styling */
.dc-icon {
    width: 40px;
    height: 40px;
    margin-right: 15px;
    border-radius: 50%;
}

/* Business name styling */
.business-name {
    font-size: 18px;
    font-weight: bold;
}

/* Demo button styling */
.demo-button {
    background-color: #007bff;
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

/* Demo button hover effect */
.demo-button:hover {
    background-color: #0056b3;
}
.chatcampaign-container{
    width: 100%;
}
.b-chats{
    display: flex;
    
}
.b-chats1{
    display: flex;
    flex-direction:column;
    justify-content: flex-start;
    gap:20px;
    margin-top:10px;
    margin-left:10px;
    width:50%;}
/* }
.b-chats2{
    display: flex;
    flex-direction:column;
    width:50%;
    align-items:flex-start;
}

.chat-form {
    display: flex;
    flex-direction: column;
 
    align-items: flex-start;
    gap: 12px;
}

.form-group22 {
    display: flex;
  
    justify-content: flex-start;
    align-items: flex-start;
    gap:10px;
}

.form-group22 textarea {
    resize: vertical;
    min-height: 80px;
    width:30vw;
}
.form-group22  label{
    margin-bottom: 8px;
    padding-left:0;
    font-weight: 600;
    color: #333;
    width:15vw;
}
.form-group22  input{
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;
    box-sizing: border-box;
} */
.b-chats2 {
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: flex-start;
    padding: 20px;
}
#chati{
    width:60px;
    height:60px;
    border-radius:100%;
    cursor:pointer;
}
.chat-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}
.color-preview{
    width: 39px;
    height:35px;
    border-radius:100%;
}

.form-group22 {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 100%;
}

.form-group22 label {
    width: 25%;
    font-weight: 600;
    color: #333;
    text-align: start;   
}

.form-group22 input,
.form-group22 textarea {
    width: 75%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    box-sizing: border-box;
}

.form-group22 textarea {
    resize: vertical;
    min-height: 80px;
}

.submit-button23 {
    padding: 12px;
    width:230px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.submit-button23:hover {
    background-color: #0056b3;
}
.color-value{
    width:60px;
}