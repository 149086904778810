.attach-btn{
    background-color:#2749a8;
    color:#fff;
    border:none;
    outline:none;
    border-radius:9px;
    padding:5px 10px;
}

.message-box{
    background-color:#2749a8;
    color:#fff;
    border-radius:9px;
    padding-left:8px;
    width:70%;
    border:none;
    outline:none;
    font-size:18px;
    height:40px;
}
.save-option{
    color:#fff;
    height:20px;
    width:23px;
}
.export-option{
    height:40px;
    width:110px;
    background-color: #fadb41;
    border:none;
    outline: none;
    border-radius:5px;
    font-weight:bold;
    cursor:pointer;
    font-size:15px;
    display:flex;
    align-items:center;
    justify-content:center;
    gap:4px;
    transition: background-color 0.2s ease-in-out;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    margin:5px 0px;
}
.export-option:hover{
    cursor:pointer;
    background-color:#dec02f;
   box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}
.message-btn{
    color:white;
    background-color:#2749a8;
    display:inline;
    padding:8px 12px;
    border-radius:6px;
    outline:none;
    border:none;
    margin-right:auto;
    margin:6px 0px;
    cursor:pointer;
}
.hero-container{
    width:83%;
    box-sizing: border-box;
    padding-left:10px;
}
.choose-data{
    display:flex;
    align-items:center;
}
.collect-data{
    display:flex;
    flex-direction:column;
}
.table-cell{
    width:19%;
    height:42px;
    background-color:#24ed6d;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    margin:10px 0px;
}
.table-cell:hover{
    background-color:#1df26a;
}
.table-cell-values{
    background-color:#e3e8e5;
    width:19%;
    height:42px;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    margin:10px 0px;
}
.table-cell-values:hover{
    background-color:#d9dadb;
}
.table-header{
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:space-evenly;
}
.table-footer{
    height:70%;
    overflow-y:auto;
}
.table-values{
    width:100%;
    display:flex;
    flex-direction:row;
    justify-content:space-evenly;
}
.file-input {
    position: relative;
    overflow: hidden;
    display: inline-block;
  }
  
  .custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    background-color: #f9f9f9;
    border-radius:9px;
    width:130px;
    margin:3px;
  }
  
  .custom-file-upload input[type="file"] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }
.success-message{
    background-color:#00FF00;
    color:#fff;
}

.failure-message{
    background-color:#FF0000;
    color:#fff;
}

.success-message:hover{
    background-color:#07f533;

}
.failure-message:hover{
    background-color:#eb0505;
}
.message-box::placeholder {
    color: #fff; /* Set placeholder color to white */
}