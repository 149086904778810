.registerUserContainer{
    display:flex;
    flex-direction:column;
    height:100vh;
    width:100vw;
    position:absolute;
    top:0;
    left:0;
}
.user-registration-form{
    height:30%;
    display:flex;
    flex-direction:column;
    justify-content:space-evenly;
    align-items:center;
    width:90%;
    gap:7px;
    padding-top:15px;
}
.user-register-input{
    border:none;
    outline:none;
    border-radius:7px;
    padding-left:8px;
    background-color:#F1F7FF;
    height:45px;
    width:80%;
}
.user-register-submit{
    height:39px;
    width:160px;
    background-color:#a124f0;
    color:#fff;
    border-radius:8px;
    border:none;
    outline:none;
    margin-top:10px;
}
.register-user-logo{
    height:33px;
    width:40px;
}
.navbar-container{
    background-color:#a124f0;
    display:flex;
    flex-direction:row;
    justify-content:center;
    align-items:center;
    gap:10px;
    height:10vh;
}
.navbar-register-user-logo{
    height:40px;
    width:80px;
}